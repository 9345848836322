import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import { Card, CardHeader, CardContent } from '@mui/material';

import LoadingContent from '../../common/loading-content';
import GadminActions from '../../../contexts/gadmin-actions';

import { useMounted } from '../../../hooks/use-mounted';
import { loadInvoicing } from '../../../api/goal/delivery/handling-api';
import { useGMDataSet } from '../../gm/dataset/use-gm-dataset';
import { useApp } from '../../../hooks/use-app';
import { GMGrid } from '../../gm/grid/gm-grid';
import { ColumnDataTypesEnum } from '../../gm/grid/grid-definitions';
import DeliveryStatus from '../../common/status/delivery-status';
import { appFormats } from '../../../api/app-definitions';
import moment from 'moment';
import { RefreshButton } from '../../common/buttons/refresh-button';
import { HROpenMenu } from './hr-open-menu';
import { useGridApiRef } from '@mui/x-data-grid';
import { HRsGroupedTable } from './hrs-grouped';
import { isTrue } from '../../../hooks/use-bolean';
import { GoalAccordion } from '../../goal-accordion';

const columns = [
  {
    field: 'HR',
    headerName: 'HR',
    type: ColumnDataTypesEnum.ID,
    description: 'Número de HR'
  },
  {
    field: 'fecprog',
    headerName: 'F Programación',
    type: ColumnDataTypesEnum.DATE,
    description: 'Fecha de programación'
  },
  {
    field: 'fecdsp',
    headerName: 'F Despacho',
    type: ColumnDataTypesEnum.DATE,
    description: 'Fecha de despacho'
  },
  {
    field: 'action',
    headerName: '',
    description: 'Acciones de HR',
    type: ColumnDataTypesEnum.MENU_ACTION,
    slot: {
      menu: HROpenMenu
    },
    slotProps: {
      menu: {}
    }
  },
  {
    field: 'branchCode',
    headerName: 'Sede',
    description: 'Sede de la hoja de reparto'
  },
  {
    field: 'zoneCode',
    headerName: 'Zona',
    description: 'Zona de la hoja de reparto'
  },
  {
    field: 'plaveh',
    headerName: 'Placa',
    description: 'Placa del vehículo',
    link: {
      to: (row) => `/delivery/vehicles/${row.plaveh}`,
      tooltip: 'Ver detalle'
    }
  },
  {
    field: 'picking_id',
    headerName: 'Picking',
    description: 'Número de picking',
    type: ColumnDataTypesEnum.ID
  },
  {
    field: 'picking_grouped',
    headerName: 'Picking agrupado',
    description: 'Picking agrupado',
    type: ColumnDataTypesEnum.BOOLEAN
  },
  {
    field: 'pendings',
    headerName: 'Pendientes',
    type: ColumnDataTypesEnum.INT,
    description: 'Cantidad de pendientes',
    link: {
      to: (row) => `/delivery/invoicing/pendings/${row.id}`,
      tooltip: 'Ver detalle',
      exclude: (row) => row.pendings == 0
    }
  },
  {
    field: 'invoiced',
    headerName: 'Facturados',
    type: ColumnDataTypesEnum.INT,
    description: 'Cantidad facturada',
    link: {
      to: (row) => `/delivery/invoicing/invoiced/${row.id}`,
      tooltip: 'Ver detalle',
      exclude: (row) => row.invoiced == 0
    }
  },
  {
    field: 'documents',
    headerName: 'Documentos',
    type: ColumnDataTypesEnum.INT,
    description: 'Cantidad de documentos',
    link: {
      to: (row) => `/delivery/invoicing/all/${row.id}`,
      tooltip: 'Ver detalle',
      exclude: (row) => row.documents == 0
    }
  },
  {
    field: 'Estado',
    headerName: 'Estado',
    description: 'Estado de la hoja de reparto',
    type: ColumnDataTypesEnum.STATUS,
    slot: {
      component: DeliveryStatus
    }
  },
  {
    field: 'groupable',
    headerName: 'Agrupable',
    description: 'Se puede agrupar para el picking',
    type: ColumnDataTypesEnum.BOOLEAN
  },

  {
    field: 'confirmed',
    headerName: 'Confirmado',
    description: 'Confirmado',
    type: ColumnDataTypesEnum.BOOLEAN
  },
  {
    field: 'greGenerated',
    headerName: 'GRE Generado',
    description: 'GRE Generado',
    type: ColumnDataTypesEnum.BOOLEAN
  },
  {
    field: 'greQueued',
    headerName: 'GRE en cola',
    description: 'GRE en cola',
    type: ColumnDataTypesEnum.BOOLEAN
  },
  {
    field: 'greSubmited',
    headerName: 'GRE Enviado',
    description: 'GRE Enviado',
    type: ColumnDataTypesEnum.BOOLEAN
  },

  // soles
  {
    field: 'money',
    headerName: 'Total',
    description: 'Total de la hoja de reparto',
    type: ColumnDataTypesEnum.TOTAL
  },
  // orders
  {
    field: 'orders',
    headerName: 'Pedidos',
    description: 'Cantidad de pedidos',
    type: ColumnDataTypesEnum.INT
  },
  {
    field: 'customers',
    headerName: 'Clientes',
    description: 'Cantidad de clientes',
    type: ColumnDataTypesEnum.INT
  },

  {
    field: 'weight',
    headerName: 'Peso',
    description: 'Peso de la hoja de reparto',
    type: ColumnDataTypesEnum.TOTAL
  },
  {
    field: 'volume',
    headerName: 'Volumen',
    description: 'Volumen de la hoja de reparto',
    type: ColumnDataTypesEnum.TOTAL
  },

  {
    field: 'jrName',
    headerName: 'Jefe de reparto',
    description: 'Jefe de reparto'
  },
  {
    field: 'emName',
    headerName: 'Encargado de mercadería',
    description: 'Encargado de mercadería'
  },
  {
    field: 'paymentCode',
    headerName: 'Cod. Pago',
    description: 'Código de pago'
  }
];

export const HRInvoicingTable = ({ date }) => {
  const { mounted } = useMounted();
  const { currentCompany } = useApp();
  const [summary, setSummary] = useState([]);

  const apiRef = useGridApiRef();

  const { handleRefresh, ...hrsState } = useGMDataSet({
    sourceApi: loadInvoicing,
    apiParameters: {
      companyId: currentCompany,
      date: moment(date).format(appFormats.backendDateFormat)
    },
    loadingMessage: 'Cargando facturacion...',
    mounted,
    columns
  });

  const handleSelectionChange = () => {
    const selectedRows = apiRef.current.getSelectedRows();
    const summaryData = {};

    selectedRows.forEach((row) => {
      // const vehicle = row.plaveh;
      // console.log('Row:', row);
      const vehicle = `${row.fecdsp}-${row.plaveh}-${row.confirmed}-${row.branchCode}-${row.jrName}-${row.emName}`;
      // console.log('Vehicle:', vehicle);
      if (!summaryData[vehicle]) {
        summaryData[vehicle] = {
          weight: 0,
          volume: 0,
          orders: 0,
          customers: 0,
          money: 0,
          pending: 0,
          invoiced: 0,
          documents: 0,
          sheets: 0,
          grouped: 0,
          ungrouped: 0,
          groupables: 0,
          sheetCodes: []
        };
      }
      summaryData[vehicle].weight += parseFloat(row.weight) || 0;
      summaryData[vehicle].volume += parseFloat(row.volume) || 0;
      summaryData[vehicle].orders += parseFloat(row.orders) || 0;
      summaryData[vehicle].customers += parseFloat(row.customers) || 0;
      summaryData[vehicle].money += parseFloat(row.money) || 0;
      summaryData[vehicle].pending += parseFloat(row.pendings) || 0;
      summaryData[vehicle].invoiced += parseFloat(row.invoiced) || 0;
      summaryData[vehicle].documents += parseFloat(row.documents) || 0;
      summaryData[vehicle].sheets += 1;
      summaryData[vehicle].jrName = row.jrName;
      summaryData[vehicle].emName = row.emName;
      summaryData[vehicle].branchCode = row.branchCode;
      summaryData[vehicle].fecdsp = row.fecdsp;
      summaryData[vehicle].confirmed = row.confirmed;
      summaryData[vehicle].plaveh = row.plaveh;
      summaryData[vehicle].grouped += isTrue(row.picking_grouped) ? 1 : 0;
      summaryData[vehicle].ungrouped += isTrue(row.picking_grouped) ? 0 : 1;
      summaryData[vehicle].groupables += isTrue(row.groupable) ? 1 : 0;
      summaryData[vehicle].sheetCodes.push(row.id);
    });

    const summaryArray = Object.keys(summaryData).map((vehicle) => ({
      id: vehicle,
      ...summaryData[vehicle],
      groupable:
        summaryData[vehicle].sheets > 1 &&
        summaryData[vehicle].ungrouped === summaryData[vehicle].sheets &&
        summaryData[vehicle].groupables === summaryData[vehicle].sheets
      // implode sheet codes
      // sheetCodes: summaryData[vehicle].sheetCodes.join(',')
    }));

    setSummary(summaryArray);
    // console.log('Summary by vehicle:', summaryArray);
  };

  useEffect(() => {
    handleRefresh();
  }, [date]);

  useEffect(() => {
    apiRef.current.subscribeEvent('rowSelectionChange', handleSelectionChange);
  }, [apiRef]);

  if (hrsState.isLoading) {
    return <LoadingContent loadingText="Cargando hojas de reparto" loading={hrsState.isLoading} />;
  }
  return (
    <Card variant="outlined">
      <CardHeader
        title="Hojas de reparto facturando"
        action={<RefreshButton onClick={handleRefresh} />}
      />
      <CardContent>
        {summary.length > 0 && (
          <GoalAccordion
            title={'Seleccionados'}
            // actions={<DeleteButton onConfirm={handleClearSelection} />}
          >
            <HRsGroupedTable data={summary} onRefresh={handleRefresh} />
          </GoalAccordion>
        )}
        <GMGrid {...hrsState} apiRef={apiRef} />
      </CardContent>
    </Card>
  );
};

HRInvoicingTable.propTypes = {
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};
