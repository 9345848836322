import PropTypes from 'prop-types';

import { useApp } from '../../../hooks/use-app';
import { useAuth } from '../../../hooks/use-auth';
import { ConfirmationMenuItem } from '../../common/confirmation-menu-item';
import { GoalMenu } from '../../common/goal-menu';
import PeopleIcon from '@mui/icons-material/People';
import { makePickingGroup } from '../../../api/goal/delivery/handling-api';

export const HRsGroupedMenu = ({ onRefresh, row }) => {
  const { currentCompany, showError, showNotify } = useApp();
  const { hasPermission } = useAuth();

  const handleMakeGroup = async () => {
    const result = await makePickingGroup({ sheets: row.sheetCodes, companyId: currentCompany });
    if (!result || !result.success) {
      showError(result.message || 'No se pudo sincronizar');
    } else {
      showNotify(result.message);
      onRefresh?.();
    }
  };

  return (
    <GoalMenu>
      {row.groupable && (
        <ConfirmationMenuItem
          startIcon={<PeopleIcon />}
          helpText={'Agrupar Hojas'}
          onConfirm={handleMakeGroup}
          label={'Agrupar Hojas'}
          confirmationMessage={'¿Está seguro que desea agrupar las hojas de reparto?'}
        />
      )}
    </GoalMenu>
  );
};

HRsGroupedMenu.propTypes = {
  onRefresh: PropTypes.func,
  row: PropTypes.object
};
