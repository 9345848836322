import { Icon } from '@iconify/react';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import GadminActions from '../../contexts/gadmin-actions';
import layerFill from '@iconify/icons-eva/layers-fill';
import flip2Outline from '@iconify/icons-eva/flip-2-outline';
import carOutline from '@iconify/icons-eva/car-outline';
import monitorOutline from '@iconify/icons-eva/monitor-outline';
import shoppingCartOutline from '@iconify/icons-eva/shopping-cart-outline';
import giftOutline from '@iconify/icons-eva/gift-outline';
import undoOutline from '@iconify/icons-eva/undo-outline';
import globeOutline from '@iconify/icons-eva/globe-outline';
import awardOutline from '@iconify/icons-eva/award-outline';
import alertTriangleOutline from '@iconify/icons-eva/alert-triangle-outline';
import archiveOutline from '@iconify/icons-eva/archive-outline';
import syncOutline from '@iconify/icons-eva/sync-outline';
import { Dog as DogIcon } from '../../icons/dog';
import ExpensesIcon from '@mui/icons-material/MonetizationOn';
import FinancialIcon from '@mui/icons-material/Book';

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const updateCompanyInAllNodes = (items, companyId) => {
  return items.reduce((acc, item) => {
    if (item.children) {
      return [
        ...acc,
        {
          ...item,
          children: updateCompanyInAllNodes(item.children, companyId)
        }
      ];
    } else {
      return [
        ...acc,
        {
          ...item,
          path: item.path.replace(':companyId', companyId)
        }
      ];
    }
  }, []);
};

// filter all children in all nodes if has permissions
const filterChildrenByPermission = (items, permissions) => {
  return items.reduce((acc, item) => {
    if (item.children) {
      return [
        ...acc,
        {
          ...item,
          children: filterChildrenByPermission(item.children, permissions)
        }
      ];
    } else {
      if (item.permissions) {
        const hasPermission = item.permissions.some((permission) =>
          permissions.includes(permission)
        );
        if (hasPermission) {
          return [...acc, item];
        } else {
          return acc;
        }
      } else {
        return acc;
      }
    }
  }, []);
};

// count all children in all nodes
const countChildren = (items) => {
  return items.reduce((acc, item) => {
    if (item.children) {
      return acc + countChildren(item.children);
    } else {
      return acc + 1;
    }
  }, 0);
};

// count all children for each nodes
const countChildrenForNodes = (items) => {
  return items.map((item) => {
    if (item.children) {
      return {
        ...item,
        count: countChildren(item.children),
        paths: getChildrenPaths(item.children)
      };
    } else {
      return {
        ...item,
        count: 0
      };
    }
  });
};

// get all children paths
const getChildrenPaths = (items) => {
  return items.reduce((acc, item) => {
    if (item.children) {
      return [...acc, ...getChildrenPaths(item.children)];
    } else {
      return [...acc, item.path];
    }
  }, []);
};

const sidebarConfig = ({ companyId, permissions }) => {
  const OriginalItems = [
    {
      title: 'Dashboard',
      path: '/app/:companyId/dashboard',
      icon: getIcon(pieChart2Fill)
    },
    {
      title: 'Compras',
      // path: '/app/:companyId/purchases',
      icon: getIcon(shoppingCartOutline),
      children: [
        {
          title: 'Facturas - Servicios',
          path: '/app/:companyId/purchases/invoices',
          permissions: [GadminActions.G2_PURCHASE_FFEE_LIST],
          icon: getIcon(layerFill)
        },
        {
          title: 'Facturas - Mercadería',
          path: '/app/:companyId/purchases/invoice-items',
          permissions: [GadminActions.G2_PURCHASE_FFEE_ITEM_LIST],
          icon: getIcon(layerFill)
        },
        {
          title: 'Notas de crédito',
          path: '/app/:companyId/purchases/nc-items',
          permissions: [GadminActions.G2_PURCHASE_NCES_LIST],
          icon: getIcon(layerFill)
        },
        {
          title: 'Notas de crédito - servicio',
          path: '/app/:companyId/purchases/nc-service',
          permissions: [GadminActions.G2_PURCHASE_NCES_LIST],
          icon: getIcon(layerFill)
        },
        {
          title: 'Notas de débito',
          path: '/app/:companyId/purchases/dn-service',
          permissions: [GadminActions.G2_PURCHASE_NCES_LIST],
          icon: getIcon(layerFill)
        },
        {
          title: 'Honorarios',
          path: '/app/:companyId/purchases/receipts-fees',
          permissions: [GadminActions.G2_PURCHASE_RRHH_LIST],
          icon: getIcon(layerFill)
        },
        {
          title: 'Pendientes de pago',
          path: '/app/:companyId/purchases/pending-to-pay',
          permissions: [GadminActions.GCC_PURCHASE_FFEE_PTPAY],
          icon: getIcon(layerFill)
        }
      ]
    },
    {
      title: 'Ventas',
      icon: getIcon(monitorOutline),
      children: [
        {
          title: 'Pedidos',
          path: '/app/:companyId/sales/orders',
          icon: getIcon(layerFill),
          permissions: [GadminActions.GCC_BROWSE_ORDER_REG]
        },
        // {
        //   title: 'Pedidos retenidos',
        //   path: '/app/:companyId/sales/orders/pendings',
        //   icon: getIcon(layerFill),
        //   permissions: [GadminActions.GCC_BROWSE_ORDER_RET]
        // },
        // {
        //   title: 'Pedidos autorizados',
        //   path: '/app/:companyId/sales/orders/authorizeds',
        //   icon: getIcon(layerFill),
        //   permissions: [GadminActions.GCC_BROWSE_ORDER_REG]
        // },
        {
          title: 'Pedidos abiertos',
          path: '/app/:companyId/sales/orders/openneds',
          icon: getIcon(layerFill),
          permissions: [GadminActions.GCC_BROWSE_ORDER_REG]
        },
        {
          title: 'Pedidos anulados',
          path: '/app/:companyId/sales/orders/cancelleds',
          icon: getIcon(layerFill),
          permissions: [GadminActions.GCC_BROWSE_ORDER_REG]
        },
        {
          title: 'Retenidos',
          path: '/app/:companyId/sales/orders/unauthorizeds',
          icon: getIcon(layerFill),
          permissions: [GadminActions.GCC_BROWSE_ORDER_REG]
        },
        {
          title: 'Promociones',
          path: '/app/:companyId/promo/assigneds',
          icon: getIcon(layerFill),
          permissions: [GadminActions.GCC_PROMO]
        }
      ]
    },
    {
      title: 'Operaciones',
      icon: getIcon(flip2Outline),
      children: [
        {
          title: 'Vehículos',
          path: '/app/:companyId/operations/vehicles',
          permissions: [GadminActions.GCC_DELIVERY_LIST_VEH],
          icon: getIcon(carOutline)
        },
        {
          title: 'Órdenes de Trabajo',
          path: '/app/:companyId/operations/work-orders',
          permissions: [GadminActions.GCC_DELIVERY_LIST_VEH],
          icon: getIcon(carOutline)
        }
      ]
    },
    {
      title: 'Inventario',
      icon: getIcon(flip2Outline),
      children: [
        {
          title: 'Almacenes',
          path: '/app/:companyId/inventory/warehouses',
          permissions: [GadminActions.GCC_DELIVERY_LIST_VEH],
          icon: getIcon(carOutline)
        },
        // price lists
        {
          title: 'Listas de precios',
          path: '/app/:companyId/inventory/price-lists',
          permissions: [GadminActions.GCC_DELIVERY_LIST_VEH],
          icon: getIcon(carOutline)
        }
      ]
    },

    {
      title: 'Despacho',
      icon: getIcon(giftOutline),
      children: [
        //   {
        //     title: 'Maestros',
        //     icon: getIcon(layerFill),
        //     children: [
        //       {
        //         title: 'Vehículos',
        //         path: '/app/:companyId/delivery/master/vehicle',
        //         icon: getIcon(layerFill)
        //       }
        //     ]
        //   },
        {
          title: 'Choferes',
          path: '/app/:companyId/delivery/drivers',
          permissions: [GadminActions.GCC_DELIVERY_CHOFERES],
          icon: getIcon(layerFill)
        },
        {
          title: 'Vehículos',
          path: '/app/:companyId/delivery/vehicles',
          permissions: [GadminActions.GCC_DELIVERY_LIST_VEH],
          icon: getIcon(layerFill)
        },
        {
          title: 'Facturación',
          path: '/app/:companyId/delivery/invoicing',
          permissions: [GadminActions.GCC_OPEN_ORDERS],
          icon: getIcon(layerFill)
        },
        {
          title: 'Apoyos',
          path: '/app/:companyId/delivery/auxiliary',
          permissions: [GadminActions.GCC_DELIVERY_PAR_APOYOREP],
          icon: getIcon(layerFill)
        },
        {
          title: 'Seguimiento reparto',
          path: '/app/:companyId/delivery/monitoring',
          icon: getIcon(layerFill),
          permissions: [GadminActions.GCC_TDOY_LIST]
        },
        {
          title: 'Planificación',
          path: '/app/:companyId/delivery/planning/78483',
          icon: getIcon(layerFill),
          permissions: [GadminActions.GCC_DELIVERY_PROG]
        },
        {
          title: 'Crear GR',
          path: '/app/:companyId/delivery/create-gr',
          icon: getIcon(layerFill),
          permissions: [GadminActions.GCC_TDOY_LIST]
        },
        {
          title: 'Carga',
          path: '/app/:companyId/delivery/upload',
          icon: getIcon(layerFill),
          permissions: [GadminActions.GCC_TDOY_LIST]
        }
      ]
    },
    {
      title: 'Devoluciones',
      icon: getIcon(undoOutline),
      children: [
        {
          title: 'Productos',
          path: '/app/:companyId/sales/returns/products',
          permissions: [GadminActions.G2_CD_LIST],
          icon: getIcon(layerFill)
        },
        {
          title: 'Motivos',
          path: '/app/:companyId/sales/returns/reasons',
          permissions: [GadminActions.G2_CD_LIST],
          icon: getIcon(layerFill)
        },
        {
          title: 'En proceso',
          path: '/app/:companyId/sales/returns/',
          permissions: [GadminActions.G2_CD_LIST],
          icon: getIcon(layerFill)
        }
      ]
    },
    {
      title: 'Contable',
      icon: <FinancialIcon />,
      children: [
        {
          title: 'Comprobantes',
          path: '/app/:companyId/accounting',
          permissions: [GadminActions.GCC_ACCT_BALANCE],
          icon: getIcon(layerFill)
        },
        {
          title: 'Balance',
          path: '/app/:companyId/accounting/balance',
          permissions: [GadminActions.G2_KARDEX_PROCESS],
          icon: getIcon(layerFill)
        }
      ]
    },
    {
      title: 'Finanzas',
      icon: <ExpensesIcon />,
      children: [
        {
          title: 'Informes de Gastos',
          path: '/app/:companyId/finance/expense-reports',
          permissions: [GadminActions.GCC_GEX_RESPORTS_LIST],
          icon: getIcon(layerFill)
        },
        {
          title: 'Gastos',
          path: '/app/:companyId/finance/expenses',
          permissions: [GadminActions.GCC_GEX_RESPORTS_LIST],
          icon: getIcon(ExpensesIcon)
        }
      ]
    },
    {
      title: 'Administración',
      icon: getIcon(globeOutline),
      children: [
        {
          title: 'Dashboard',
          path: '/app/:companyId/managment',
          permissions: [GadminActions.GCC_GPROC_MNGMT],
          icon: getIcon(layerFill)
        },
        {
          title: 'Sericios GProc',
          path: '/app/:companyId/purchases',
          permissions: [GadminActions.GCC_GPROC_SERVICES],
          icon: getIcon(layerFill)
        },

        {
          title: 'Reportes Gex',
          path: '/app/:companyId/managment/gex-reports',
          permissions: [GadminActions.GCC_GEX_RESPORTS_LIST],
          icon: getIcon(layerFill)
        },
        {
          title: 'Reportes Dex',
          path: '/app/:companyId/managment/dex-reports',
          permissions: [GadminActions.GCC_GEX_RESPORTS_LIST],
          icon: getIcon(layerFill)
        },
        {
          title: 'Colas',
          path: '/app/:companyId/managment/queues',
          icon: getIcon(pieChart2Fill),
          permissions: [GadminActions.GCC_GPROC_QUEUE]
        },
        {
          title: 'Configuración',
          path: '/app/:companyId/managment/config',
          icon: getIcon(layerFill),
          permissions: [GadminActions.GCC_GPROC_MNGMT]
        },
        {
          title: 'Importar',
          path: '/app/:companyId/managment/import',
          permissions: [GadminActions.GCC_GEX_RESPORTS_LIST],
          icon: getIcon(layerFill)
        },
        {
          title: 'Módulos',
          path: '/app/:companyId/managment/modules',
          permissions: [GadminActions.GCC_GEX_RESPORTS_LIST],
          icon: getIcon(layerFill)
        },
        {
          title: 'Usuarios',
          path: '/app/:companyId/managment/users',
          permissions: [GadminActions.GCC_GEX_RESPORTS_LIST],
          icon: getIcon(layerFill)
        },
        {
          title: 'Roles',
          path: '/app/:companyId/managment/roles',
          permissions: [GadminActions.GCC_GEX_RESPORTS_LIST],
          icon: getIcon(layerFill)
        },
        {
          title: 'Permisos',
          path: '/app/:companyId/managment/permissions',
          permissions: [GadminActions.GCC_GEX_RESPORTS_LIST],
          icon: getIcon(layerFill)
        }
      ]
    },
    {
      title: 'Encuesta',
      // icon: getIcon(alertCircleOutline),
      icon: <DogIcon />,
      children: [
        {
          title: 'Cuestionario',
          path: '/app/:companyId/survey/questionnaire',
          permissions: [GadminActions.G2_QUESTIONARY_LIST],
          icon: getIcon(layerFill)
        },
        {
          title: 'Encuesta',
          path: '/app/:companyId/survey/survey',
          permissions: [GadminActions.G2_SURVEY_LIST],
          icon: getIcon(layerFill)
        }
      ]
    },
    {
      title: 'Comercial',
      icon: getIcon(awardOutline),
      children: [
        {
          title: 'Factores Comisiones vendedores minoristas',
          path: '/app/:companyId/commercial/retailers-commissions',
          permissions: [GadminActions.G2_CONTESTS_LIST],
          icon: getIcon(layerFill)
        },
        {
          title: 'Factores Comisiones vendedores mayoristas',
          path: '/app/:companyId/commercial/wholesalers-commissions',
          permissions: [GadminActions.G2_CONTESTS_LIST],
          icon: getIcon(layerFill)
        },
        {
          title: 'Factores Comisiones Supervisores',
          path: '/app/:companyId/commercial/supervisors-commissions',
          permissions: [GadminActions.G2_CONTESTS_LIST],
          icon: getIcon(layerFill)
        },
        {
          title: 'Factores comisiones Jefes Zonales',
          path: '/app/:companyId/commercial/zone-managers-commissions',
          permissions: [GadminActions.G2_CONTESTS_LIST],
          icon: getIcon(layerFill)
        },
        {
          title: 'Factores comisiones Jefes Regionales',
          path: '/app/:companyId/commercial/regional-managers-commissions',
          permissions: [GadminActions.G2_CONTESTS_LIST],
          icon: getIcon(layerFill)
        },
        {
          title: 'Promociones',
          path: '/app/:companyId/commercial/promotion',
          permissions: [GadminActions.G2_CONTESTS_LIST],
          icon: getIcon(layerFill)
        },
        {
          title: 'Concursos',
          path: '/app/:companyId/commercial/contest',
          icon: getIcon(layerFill),
          permissions: [GadminActions.G2_CONTESTS_LIST]
        },
        {
          title: 'Mesas',
          path: '/app/:companyId/commercial/sales-teams',
          icon: getIcon(layerFill),
          permissions: [GadminActions.G2_CONTESTS_LIST]
        },
        {
          title: 'Seguimiento Preventa',
          path: '/app/:companyId/commercial/pre-sale/014',
          icon: getIcon(layerFill),
          permissions: [GadminActions.GCC_BROWSE_VISITA_DIA]
        }
      ]
    },
    {
      title: 'Datos Maestros',
      icon: getIcon(archiveOutline),
      children: [
        {
          title: 'Empresas',
          path: '/app/:companyId/master-data/companies',
          icon: getIcon(layerFill),
          permissions: [GadminActions.G2_COMPANY_LIST]
        },
        {
          title: 'Grupos de Socio',
          path: '/app/:companyId/master-data/business-partner-group',
          icon: getIcon(layerFill),
          permissions: [GadminActions.G2_CONTESTS_LIST]
        },
        {
          title: 'Marcas',
          path: '/app/:companyId/master-data/brand',
          icon: getIcon(layerFill),
          permissions: [GadminActions.G2_CONTESTS_LIST]
        },
        {
          title: 'Líneas',
          path: '/app/:companyId/master-data/lines',
          icon: getIcon(layerFill),
          permissions: [GadminActions.G2_CONTESTS_LIST]
        },
        {
          title: 'Clasificación',
          path: '/app/:companyId/master-data/classification',
          icon: getIcon(layerFill),
          permissions: [GadminActions.G2_CONTESTS_LIST]
        },
        {
          title: 'Categorias',
          path: '/app/:companyId/master-data/categories',
          icon: getIcon(layerFill),
          permissions: [GadminActions.G2_CONTESTS_LIST]
        },
        {
          title: 'Grupos de Atributos',
          path: '/app/:companyId/master-data/attribute-groups',
          icon: getIcon(layerFill),
          permissions: [GadminActions.G2_CONTESTS_LIST]
        },
        {
          title: 'Territorios',
          path: '/app/:companyId/master-data/territories',
          icon: getIcon(layerFill),
          permissions: [GadminActions.G2_CONTESTS_LIST]
        },
        {
          title: 'Giro de negocio',
          path: '/app/:companyId/master-data/line-of-business',
          icon: getIcon(layerFill),
          permissions: [GadminActions.G2_CONTESTS_LIST]
        },
        {
          title: 'Divisiones',
          path: '/app/:companyId/master-data/divisions',
          icon: getIcon(layerFill),
          permissions: [GadminActions.G2_CONTESTS_LIST]
        },
        {
          title: 'Sedes',
          path: '/app/:companyId/master-data/branchs',
          icon: getIcon(layerFill),
          permissions: [GadminActions.G2_CONTESTS_LIST]
        },
        {
          title: 'Puestos de empleado',
          path: '/app/:companyId/master-data/employee-position',
          icon: getIcon(layerFill),
          permissions: [GadminActions.G2_CONTESTS_LIST]
        },
        {
          title: 'Tipos de comprobantes',
          path: '/app/:companyId/master-data/types-of-vouchers',
          icon: getIcon(layerFill),
          permissions: [GadminActions.G2_CONTESTS_LIST]
        },
        {
          title: 'Grupos de fuerza de venta',
          path: '/app/:companyId/master-data/sales-force-groups',
          icon: getIcon(layerFill),
          permissions: [GadminActions.G2_CONTESTS_LIST]
        },
        {
          title: 'Términos de pago',
          path: '/app/:companyId/master-data/payment-terms',
          icon: getIcon(layerFill),
          permissions: [GadminActions.G2_CONTESTS_LIST]
        },
        {
          title: 'Departamentos',
          path: '/app/:companyId/master-data/departments',
          icon: getIcon(layerFill),
          permissions: [GadminActions.G2_CONTESTS_LIST]
        },
        {
          title: 'Categorias de incidencias',
          path: '/app/:companyId/master-data/incident-categories',
          icon: getIcon(layerFill),
          permissions: [GadminActions.G2_CONTESTS_LIST]
        },
        {
          title: 'Tipos de archivos adjuntos',
          path: '/app/:companyId/master-data/attachment-types',
          icon: getIcon(layerFill),
          permissions: [GadminActions.G2_CONTESTS_LIST]
        },
        {
          title: 'Plantillas de archivos adjuntos',
          path: '/app/:companyId/master-data/attachment-templates',
          icon: getIcon(layerFill),
          permissions: [GadminActions.G2_CONTESTS_LIST]
        },
        {
          title: 'Grupos de archivos adjuntos',
          path: '/app/:companyId/master-data/attachment-groups',
          icon: getIcon(layerFill),
          permissions: [GadminActions.G2_CONTESTS_LIST]
        }
      ]
    },
    {
      title: 'Incidencias',
      icon: getIcon(alertTriangleOutline),
      children: [
        {
          title: 'Incidencias',
          path: '/app/:companyId/incidents/incidents',
          permissions: [GadminActions.G2_QUESTIONARY_LIST],
          icon: getIcon(layerFill)
        }
      ]
    },
    {
      title: 'Automatizaciones',
      icon: getIcon(syncOutline),
      children: [
        {
          title: 'Bulks',
          path: '/app/:companyId/automations/bulks',
          permissions: [GadminActions.G2_BULK_LIST],
          icon: getIcon(layerFill)
        },
        {
          title: 'Queues',
          path: '/app/:companyId/automations/queues',
          permissions: [GadminActions.G2_QUEUE_LIST],
          icon: getIcon(layerFill)
        },
        {
          title: 'Servers',
          path: '/app/:companyId/automations/goal-servers',
          permissions: [GadminActions.G2_QUEUE_LIST],
          icon: getIcon(layerFill)
        },
        {
          title: 'Sincronización',
          path: '/app/:companyId/automations/sync-objects',
          permissions: [GadminActions.G2_QUEUE_LIST],
          icon: getIcon(layerFill)
        }
      ]
    }
  ];

  const newItems = updateCompanyInAllNodes(OriginalItems, companyId);

  // filter items with permissions
  const filteredByPermissionItems = filterChildrenByPermission(newItems, permissions);

  const counted = countChildrenForNodes(filteredByPermissionItems);

  // remove items with children count = 0
  const filteredItems = counted.filter((item) => item.count > 0);

  return filteredItems;
};

export default sidebarConfig;
